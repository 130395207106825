import * as React from 'react';

// import { navigate } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import SignUpContext from '../../contexts/SignUpContext';
import Affiliate from '../Attribution/Affiliate';
import ComparisonChart from '../ComparisonChart';
import CreditScoreDifference from '../CreditScoreDifference';
import Faq from '../Faq/Faq';
import FlodeskCreditChallengeForm from '../Flodesk/CreditChallengeForm';
import HomeFeatures from '../Home/HomeFeatures';
import HomeHero from '../Home/HomeHero';
import HomeHowItWorks from '../Home/HomeHowItWorks';
import InterestChart from '../InterestChart';
import Testimonials from '../Testimonials';
import '../../scss/styles.scss';
import BasicPanel from '../BasicPanel';
import CreditVocab from '../CreditVocab';
import CreditCurriculum from '../CreditCurriculum';
import VideoClips from '../VideoClips';

// Landing URL: set cookie and redirect to /
const LandingPage = ({ pageContext }) => {
  return (
    <StaticQuery
      query={graphql`
        query LandingPageQuery {
          site {
            siteMetadata {
              app_url
            }
          }
          contentfulLayoutElements {
            headerLogo {
              url
              title
            }
          }
          allContentfulLandingPage {
            nodes {
              slug
              mainHeader {
                childMarkdownRemark {
                  html
                }
              }
              heroSubheader {
                childMarkdownRemark {
                  html
                }
              }
              heroButtonText
              heroSubtext
              mobileHeroImage {
                url
                title
                mimeType
              }
              desktopHeroImage {
                url
                title
                mimeType
              }
              pressFeatureLogos {
                url
                title
              }
              testimonialHeader {
                childMarkdownRemark {
                  html
                }
              }
              testimonialData {
                title
                content
                customerName
                customerAge
              }
              interestChartData {
                bank
                apy
                rate
                fees
              }  
              interestChartSection {
                header
                inputLabel
                calculateButton
                estimatedEarningsHeader
                overdraftFeesHeader
                disclaimer {
                  part1
                  linkText
                  linkUrl
                  part2
                }
                popupHeader
                popupText
                submitButton
                dailyBalancePlaceholder
                membershipFeeHeader
              }
              pinkTaxPanelContent {
                title
                subtitle
                body
                cta
              }
              pinkTaxPanelImage {
                url
                title
              }
              howItWorksData {
                header
                additionalText {
                  joinToday
                  moreQuestions
                }
                howItWorksSteps {
                  heading
                  description
                }
              }
              howItWorksImages {
                url
                title
              }
            }
          }
        }
      `}
      render={data => {
        const content = {
          ...data?.allContentfulLandingPage?.nodes[0],
          mainHeader:
            pageContext.mainHeader ||
            data?.allContentfulLandingPage?.nodes[0]?.mainHeader,
          heroSubheader:
            pageContext.heroSubheader ||
            data?.allContentfulLandingPage?.nodes[0]?.heroSubheader
        };
        const testimonialHeader = content?.testimonialHeader;
        const testimonials = content?.testimonialData;
        const interestChartData = content?.interestChartData;
        const interestChartSection = content?.interestChartSection;
        const howItWorksData = content?.howItWorksData;
        const howItWorksImages = content?.howItWorksImages;
        const pinkTaxPanel = content?.pinkTaxPanelContent;
        const pinkTaxPanelImage = content?.pinkTaxPanelImage;
        // const videoClips = content?.videoClips;

        // const headerLogo = data?.contentfulLayoutElements?.headerLogo;

        return (
          <SignUpContext.Provider value={{ flodesk: true }}>
            <Affiliate
              source={pageContext.source}
              affiliate={pageContext.source}
              noRedirect={true}
              notificationText={pageContext.notificationText}
            />
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <main>
              <HomeHero content={content} />
              <HomeFeatures content={content} />
              {/* <VideoClips content={videoClips} /> */}
              <CreditScoreDifference />
              <CreditVocab />


              {/* <ComparisonChart />
            <InterestChart
              interestChartData={interestChartData}
              interestChartSection={interestChartSection}
            /> */}

              <BasicPanel
                title={pinkTaxPanel.title}
                subtitle={pinkTaxPanel.subtitle}
                body={pinkTaxPanel.body}
                headerImage={pinkTaxPanelImage}
                cta={pinkTaxPanel.cta}
              />

              <CreditCurriculum />

              <Testimonials header={testimonialHeader} testimonials={testimonials} />
              <HomeHowItWorks
                howItWorksData={howItWorksData}
                howItWorksImages={howItWorksImages}
              />
              {/* <FlodeskCreditChallengeForm /> */}
              <Faq />
            </main>
          </SignUpContext.Provider>
        );
      }}
    />
  );
};

export default LandingPage;
